<template>
  <div>
    <el-upload
      style="margin-left: 8px"
      class="upload-demo inline-block margin-right-10"
      action=""
      :on-change="handleChange"
      :show-file-list="false"
      accept=".csv, .xlsx, .xls"
      :auto-upload="false"
      ref="upload"
      :disabled="!isCustomSetting"
    >
      <slot></slot>
    </el-upload>
  </div>
</template>

<script>
import axios from 'axios'
import myaxios from 'axios'
import XLSX from 'xlsx'

import exportImport from './importExcel.vue'

export default {
  props: {
    isCustomSetting: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    exportImport,
  },
  data() {
    return {}
  },
  methods: {
    handleChange(file, fileList) {
      let files = { 0: file.raw }
      if (!/\.(csv|xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          type: 'info',
          message: '上传格式不正确, 请上传xls, csv, xlsx文件',
          duration: 1500,
          offset: 70,
        })
        return
      }
      if (/\.(csv)$/.test(files[0].name.toLowerCase())) {
        this.readCsv(files)
      } else {
        this.readExcel1(files)
      }
    },
    // 导入csv文件时
    readCsv(files) {
      //表格导入
      if (files.length <= 0) {
        this.$message({
          type: 'info',
          message: '请上传文件',
          duration: 1500,
          offset: 70,
        })
        return false
      } else if (!/\.(csv)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: '上传格式不正确，请上传xls或者xlsx格式',
          type: 'info',
          duration: '1500',
          offset: 70,
        })
        return false
      }
      const fileReader = new FileReader()
      fileReader.readAsText(files[0], 'GBK')
      fileReader.onload = (ev) => {
        this.$emit('getExcelData', ev, 'csv')
      }
    },
    // 导入xls文件或xlsx文件时
    readExcel1(files) {
      //表格导入
      if (files.length <= 0) {
        //如果没有文件名
        this.$message({
          type: 'info',
          message: '请上传文件',
          duration: 1500,
          offset: 70,
        })
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: '上传格式不正确，请上传xls或者xlsx格式',
          type: 'info',
          duration: '1500',
          offset: 70,
        })
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0] //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]) //生成json表格内容
          this.$emit(
            'getExcelData',
            ws,
            files[0].name.toLowerCase().split('.')[1]
          )
          // this.$nextTick(() => {
          //   this.$refs.upload.clearfiles();
          // })
          try {
          } catch (err) {}
          this.$refs.upload.value = ''
        } catch (e) {
          return false
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
  },
  created() {},
}
</script>

<style scoped lang="less">
/deep/.el-upload {
  outline: none;
  .el-upload__input {
    display: none;
  }
}
</style>
