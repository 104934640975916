export const electronicFormTextObj = {
  fileFormat: '输出开料清单文件格式',
  isSmallFile: '导出小板图片文件',
  useCategoryFolder: '电子锯文件根据颜色、材质、板厚分不同表格',
  rooms_by_category: '电子锯文件根据房间分不同表格',
  fileName: '自定义开料清单',
  outputFileEncoding: '输出清单编码格式',
  acrossTexture: '横纹',
  verticalTexture: '竖纹',
  noneTexture: '无纹理',
  cellTabs: '生成单元格制表符',
  genSimpleLabelBarcode: '生成简易格式标签条码',
  uniqueBarCode: '板件条码唯一',
}

export const electronicFormValueObj = {
  outputFileEncoding: {
    gbk: 'GBK',
    'utf-8-sig': 'UTF-8带BOM',
  },
}
